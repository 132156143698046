'use client';

import { Trans } from '@lingui/macro';
import { CircularProgress, Stack, Typography } from '@mui/material';
import type { FC } from 'react';
export type CompleteProps = {};
export const Complete: FC<CompleteProps> = props => {
  return <Stack spacing={4} alignItems="center" data-sentry-element="Stack" data-sentry-component="Complete" data-sentry-source-file="complete.tsx">
      <CircularProgress data-sentry-element="CircularProgress" data-sentry-source-file="complete.tsx" />
      <Typography data-sentry-element="Typography" data-sentry-source-file="complete.tsx">
        <Trans data-sentry-element="Trans" data-sentry-source-file="complete.tsx">Vos données sont en cours de validation...</Trans>
      </Typography>
    </Stack>;
};