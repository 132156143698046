'use client';

import type { OrganizationFormMetadataT, OrganizationVolumeT } from '@prismo-io/schemas';
import { type FC, type PropsWithChildren, createContext, useCallback, useContext, useEffect, useState } from 'react';
import type { InformationsSchemaRefineT } from '../informations/schema';
import type { UseCaseSchemaT } from '../use-case';
import type { VerificationCodeSchemaT } from '../verification-code/schema';
import type { VolumeSchemaT } from '../volume';
const FORM_STATE = {
  selectedIndex: 0,
  steps: {
    useCases: {
      valid: false,
      dirty: false,
      value: {
        useCases: [] as UseCaseSchemaT['useCases']
      } satisfies UseCaseSchemaT
    },
    volume: {
      valid: false,
      dirty: false,
      value: {
        talentVolume: null as unknown as OrganizationVolumeT
      } satisfies VolumeSchemaT
    },
    informations: {
      valid: false,
      dirty: false,
      value: {
        name: '',
        email: '',
        job: '',
        phone: ''
      } satisfies InformationsSchemaRefineT
    },
    verificationCode: {
      valid: false,
      dirty: false,
      value: {
        verificationCode: ''
      } satisfies VerificationCodeSchemaT
    }
  }
};
const FormStateContext = createContext({
  form: FORM_STATE,
  setForm: (form: typeof FORM_STATE | ((form: typeof FORM_STATE) => typeof FORM_STATE)) => {},
  prev: () => {},
  next: () => {},
  setSelectedIndex: (index: number) => {}
});
export const useFormState = () => {
  const ctx = useContext(FormStateContext);
  if (!ctx) {
    throw new Error('useFormState() must be used inside <FormStateProvider />');
  }
  return ctx;
};
type Props = {
  defaultEmail: string;
  metadata?: OrganizationFormMetadataT;
  // onComplete: (state: typeof FORM_STATE) => void;
};
export const FormStateProvider: FC<PropsWithChildren<Props>> = props => {
  const {
    children,
    metadata = {} as OrganizationFormMetadataT,
    defaultEmail
  } = props;
  const [form, setForm] = useState(() => ({
    ...FORM_STATE,
    selectedIndex: 'useCases' in metadata && 'talentVolume' in metadata ? 2 : 0,
    steps: {
      ...FORM_STATE.steps,
      useCases: {
        valid: 'useCases' in metadata,
        dirty: false,
        value: {
          ...FORM_STATE.steps.useCases.value,
          ...metadata
          // useCases: 'useCases' in metadata ? metadata.useCases as UseCaseSchemaT['useCases'] : [] as UseCaseSchemaT['useCases'],
        } satisfies UseCaseSchemaT
      },
      volume: {
        valid: 'talentVolume' in metadata,
        dirty: false,
        value: {
          ...FORM_STATE.steps.volume.value,
          ...metadata
          // talentVolume: null as unknown as OrganizationVolumeT,
        } satisfies VolumeSchemaT
      },
      informations: {
        valid: false,
        dirty: false,
        value: {
          ...FORM_STATE.steps.informations.value,
          ...metadata
          // name: '',
          // email: '',
          // job: '',
          // phone: '',
        } satisfies InformationsSchemaRefineT
      },
      verificationCode: {
        valid: false,
        dirty: false,
        value: {
          verificationCode: ''
        } satisfies VerificationCodeSchemaT
      }
    }
  }));

  // useEffect(() => {
  //   setForm((prev) => ({
  //     ...prev,
  //     steps: {
  //       ...prev.steps,
  //       informations: {
  //         ...prev.steps.informations,
  //         value: {
  //           ...prev.steps.informations.value,
  //           email: defaultEmail,
  //         },
  //       },
  //     },
  //   }));
  // }, [setForm, defaultEmail]);

  const prev = useCallback(() => {
    setForm(prev => ({
      ...prev,
      selectedIndex: prev.selectedIndex - 1 > 0 ? prev.selectedIndex - 1 : 0
    }));
  }, [setForm]);
  const next = useCallback(() => {
    setForm(prev => ({
      ...prev,
      selectedIndex: prev.selectedIndex + 1
    }));
  }, [setForm]);
  const setSelectedIndex = useCallback((index: number) => {
    setForm(prev => ({
      ...prev,
      selectedIndex: index
    }));
  }, [setForm]);
  return <FormStateContext.Provider value={{
    form,
    setForm,
    prev,
    next,
    setSelectedIndex
  }} data-sentry-element="unknown" data-sentry-component="FormStateProvider" data-sentry-source-file="context.tsx">
      {children}
    </FormStateContext.Provider>;
};