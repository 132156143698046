'use client';

import { useApi } from '@prismo-io/core';
import type { SWRArgs, ToolsOrganizationsT } from '@prismo-io/schemas';
import { useUsermaven } from '@prismo-io/tracking';
import useSWRMutation from 'swr/mutation';
import { createOrganization } from './create-organization';
import type { CreationSchemaT } from './schema';
export const useCreateOrganization = () => {
  const {
    client
  } = useApi();
  const usermaven = useUsermaven();
  return useSWRMutation(['create-organization'], async (key, {
    arg
  }: SWRArgs<CreationSchemaT>) => {
    const result = await createOrganization(client, arg);
    if (result) {
      const {
        data: {
          session
        }
      } = await client.auth.getSession();
      if (session) {
        usermaven.id({
          id: session.user.id,
          email: session.user.email,
          custom: {
            job: arg.job,
            phone: arg.phone
          },
          company: {
            id: result.id,
            name: result.name,
            created_at: new Date(result.created_at).toISOString(),
            custom: {
              usages: arg.useCases.join(', '),
              volume: arg.talentVolume
            }
          }
        });
        usermaven.track('company_created', {
          name: result.name
        });
      }
    }
    return result as ToolsOrganizationsT;
  });
};