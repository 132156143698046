'use client';

import { type ComponentProps, type FC, useCallback, useMemo } from 'react';
import { useFormState } from '../creation/context';
import { VolumeForm } from '../volume';
type FormProps = ComponentProps<typeof VolumeForm>;
type Props = Omit<FormProps, 'onSubmit' | 'useCases' | 'defaultValues'>;
export const VolumeFormWrapper: FC<Props> = props => {
  const {
    setForm,
    next,
    form
  } = useFormState();
  const onSubmit: FormProps['onSubmit'] = useCallback(data => {
    setForm(prev => ({
      ...prev,
      steps: {
        ...prev.steps,
        volume: {
          ...prev.steps.volume,
          valid: false,
          dirty: false,
          value: data
        }
      }
    }) as typeof prev);
    return next();
  }, [setForm, next]);

  // useEffect(() => {
  //   setForm((prev) => ({
  //     ...prev,
  //     steps: {
  //       ...prev.steps,
  //       volume: {
  //         ...prev.steps.volume,
  //         dirty: isDirty,
  //       },
  //     },
  //   }));
  // }, [isDirty, setForm]);

  const useCases = useMemo(() => form.steps.useCases.value.useCases, [form]);
  const defaultValues = useMemo(() => form.steps.volume.value, [form]);
  return <VolumeForm {...props} onSubmit={onSubmit} useCases={useCases} defaultValues={defaultValues} data-sentry-element="VolumeForm" data-sentry-component="VolumeFormWrapper" data-sentry-source-file="volume.wrapper.tsx" />;
};