'use client';

import { type ComponentProps, type FC, useCallback, useMemo } from 'react';
import { useFormState } from '../creation/context';
import { InformationsForm } from '../informations';
type FormProps = ComponentProps<typeof InformationsForm>;
type Props = Omit<FormProps, 'onSubmit' | 'defaultValues'>;
export const InformationsFormWrapper: FC<Props> = props => {
  const {
    setForm,
    next,
    form
  } = useFormState();
  const onSubmit: FormProps['onSubmit'] = useCallback(data => {
    setForm(prev => ({
      ...prev,
      steps: {
        ...prev.steps,
        informations: {
          ...prev.steps.informations,
          valid: true,
          dirty: false,
          value: data
        }
      }
    }) as typeof prev);
    return next();
  }, [setForm, next]);

  // useEffect(() => {
  //   setForm((prev) => ({
  //     ...prev,
  //     steps: {
  //       ...prev.steps,
  //       informations: {
  //         ...prev.steps.informations,
  //         dirty: isDirty,
  //       },
  //     },
  //   }));
  // }, [isDirty, setForm]);

  const defaultValues = useMemo(() => form.steps.informations.value, [form]);
  return <InformationsForm {...props} onSubmit={onSubmit} defaultValues={defaultValues} data-sentry-element="InformationsForm" data-sentry-component="InformationsFormWrapper" data-sentry-source-file="infromations.wrapper.tsx" />;
};