'use client';

import type { OrganizationVolumeT } from '@prismo-io/schemas';
import { type ComponentProps, type FC, useCallback, useMemo } from 'react';
import { useFormState } from '../creation/context';
import { UseCaseForm } from '../use-case';
type FormProps = ComponentProps<typeof UseCaseForm>;
type Props = Omit<FormProps, 'onSubmit' | 'defaultValues'>;
export const UseCasesFormWrapper: FC<Props> = props => {
  const {
    setForm,
    next,
    form
  } = useFormState();
  const onSubmit: FormProps['onSubmit'] = useCallback(data => {
    setForm(prev => ({
      ...prev,
      steps: {
        ...prev.steps,
        useCases: {
          dirty: false,
          valid: true,
          value: data
        }
      }
    }) as typeof prev);
    return next();
  }, [setForm, next]);

  // useEffect(() => {
  //   setForm((prev) => ({
  //     ...prev,
  //     steps: {
  //       ...prev.steps,
  //       useCases: {
  //         ...prev.steps.useCases,
  //         dirty: isDirty,
  //       },
  //     },
  //   }));
  // }, [isDirty, setForm]);

  const defaultValues = useMemo(() => form.steps.useCases.value, [form]);
  return <UseCaseForm {...props} defaultValues={defaultValues} onSubmit={onSubmit} data-sentry-element="UseCaseForm" data-sentry-component="UseCasesFormWrapper" data-sentry-source-file="use-case.wrapper.tsx" />;
};